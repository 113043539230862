@import '~antd/dist/antd.less';
@import '~antd-button-color/dist/css/style.less';
@import './antd-custom.less'; 

// RangePicker
.ant-picker-date-range-wrapper .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-date-range-wrapper .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-date-range-wrapper .ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-date-range-wrapper .ant-picker-panel-container,
.ant-picker-date-range-wrapper .ant-picker-footer {
  width: 280px !important;
}

.ant-picker-date-range-wrapper .ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

// Collapse
*::-webkit-scrollbar {
  display: none;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

// Page
.react-pdf__Page__canvas {
  width: 100% !important;
  height: unset !important;
}

// Modal
.modal-body-no-border .ant-modal-body {
  padding: 0;
}